import { each } from 'jquery';

$(function () {
  if ($('#fabrics-inner-search') || $('#fabric_favorite_search')) {
    // $('.form_select2').select2();
    // $('.form_multiple_select2').select2();

    $('.fabric_draft_material_name').on('change', function () {
      var id = $(this).val();
      if (!id) {
        $breakdown.html('<option value=""></option>');
        $selected.html('');
        return;
      }

      var $block = $(this).closest('.fabric_draft_material');
      var $breakdown = $block.find('.form_multiple_select2');
      var $selected = $breakdown
        .closest('.form-group')
        .find('.select2-selection__rendered');
      $.ajax({
        url: '/supplier/materials',
        type: 'get',
        dataType: 'json',
        data: {
          q: { parent_id_eq: id },
        },
      })
        .done(function (response) {
          var options = '';
          if (response.length > 0) {
            $.each(response, function (i, material) {
              options +=
                '<option value="' +
                material.id +
                '">' +
                material.name +
                '</option>';
            });
          }
          $breakdown.html(options);
          $selected.html('');
        })
        .fail(function () {
          $breakdown.html('<option value=""></option>');
          $selected.html('');
        });
    });

    var MultiSelector = {
      init: function (prefix) {
        this.prefix = prefix;
        $(document).on(
          'change',
          '.' + this.prefix + 's_select',
          this.onSelectChange.bind(this),
        );
        $(document).on(
          'click',
          '.' + this.prefix + '_add_btn',
          this.onClickAddButton.bind(this),
        );
        $(document).on(
          'click',
          '.' + this.prefix + '_del_btn',
          this.onClickDeleteButton.bind(this),
        );
        this.url = $('.' + this.prefix + 's_select').data('url');
        this.name = $('.' + this.prefix + 's_select').data('name');
      },
      onSelectChange: function (e) {
        this.$selected = $(e.target);
        var id = this.$selected.val();
        var depth = this.$selected.data('depth') + 1;
        this.clearWrappers(depth);
        if (!id) {
          return;
        }
        var $block = this.findOrCreateWrapper(depth);
        var self = this;
        $.ajax({
          url: self.url,
          type: 'get',
          dataType: 'json',
          data: {
            q: { parent_id_eq: id },
          },
        })
          .done(function (response) {
            var html = '';
            if (response.length == 0) {
              html =
                '<a class="' +
                self.prefix +
                '_add_btn" data-id="' +
                id +
                '" style="height: 37px; display: inline-block;">' +
                $('#search-add-btn').data('text') +
                '</a>';
            } else {
              html +=
                '<select class="' +
                self.prefix +
                's_select" data-depth="' +
                depth +
                '" style="height: 37px">';
              html += self.buildOptions(response);
              html += '</select>';
            }
            $block.html(html);
          })
          .fail(function () {
            console.log('error');
          });
      },

      onClickAddButton: function (e) {
        var id = $(e.target).data('id');
        if ($('.' + this.prefix + '_id_' + id).length > 0) {
          // 追加済みの場合は、これ以上追加しない
          return;
        }

        var html =
          '<div class="result ' +
          this.prefix +
          ' ' +
          this.prefix +
          '_id_' +
          id +
          '" style="display:flex;">';
        html +=
          '<input type="hidden" name="' + this.name + '" value="' + id + '">';
        html += this.buildPathName() + '<br>';
        html +=
          '<span class="multi-select_del_btn ' + this.prefix + '_del_btn">';
        html += '✕';
        html += '</span>';
        html += '</div>';

        this.addTag(html);
      },
      addTag: function (html) {
        if (this.prefix == 'fabric_category') {
          $('.tags').append(html);
          return;
        }

        // 同種類のタグが追加されている場合、同種類のタグの最後に追加
        var $prefixTags = $('.tags .' + this.prefix);
        if ($prefixTags.length > 0) {
          $prefixTags.last().after(html);
          return;
        }

        // 機能・加工のタグは、素材種類がある場合、その最後に追加
        if (
          this.prefix == 'fabric_function' &&
          $('.tags .fabric_processing').length > 0
        ) {
          $('.tags .fabric_processing').last().after(html);
          return;
        }

        // 先頭に追加
        $('.tags').prepend(html);
      },

      onClickDeleteButton: function (e) {
        $(e.target).closest('div').remove();
      },

      buildPathName: function () {
        var names = [];
        $.each($('.' + this.prefix + 's_select'), function (i, select) {
          names.push($('option:selected', select).text());
        });
        return names[names.length - 1];
      },
      buildOptions: function (list) {
        var options =
          '<option value="">' +
          $('#search-select-msg').data('text') +
          '</option>';
        $.each(list, function (i, pattern) {
          options +=
            '<option value="' + pattern.id + '">' + pattern.name + '</option>';
        });
        return options;
      },

      clearWrappers: function (depth) {
        for (var i = depth; i < 10; i++) {
          var $block = this.getWrapper(i);
          if ($block.length == 0) {
            return;
          }
          $block.html('');
        }
      },

      findOrCreateWrapper: function (depth) {
        var $block = this.getWrapper(depth);
        if ($block.length == 0) {
          var clsName = this.getWrapperClassName(depth);
          var divBlock =
            '<div class="multi-select_wrapper ' +
            this.prefix +
            's_select_wrapper ' +
            clsName +
            '"></div>';
          this.$selected
            .closest('.' + this.prefix + 's_selects')
            .append(divBlock);
          $block = this.getWrapper(depth);
        }

        return $block;
      },

      getWrapper: function (depth) {
        return $('.' + this.getWrapperClassName(depth));
      },

      getWrapperClassName: function (depth) {
        return this.prefix + 's_select_wrapper_' + depth;
      },
    };
    $('.multi-select').each(function (i, o) {
      console.log($(o));
      Object.create(MultiSelector).init($(o).data('prefix'));
    });
    $('body').on('click', '#more-link', function () {
      $('#more-link').hide();
      $('.icon_loader').show();
    });
    $('#size').on('change', function () {
      $('#fabrics-inner-search').submit();
    });
    $('#sort').on('change', function () {
      $('#fabrics-inner-search').submit();
    });
    var FabricSearchResetButton = {
      init: function ($button) {
        $button.on('click', this.onClick.bind(this));
      },

      onClick: function () {
        this.clearInputs('#composition_content');
        this.clearInputs('#conditions_content');
        this.clearInputs('.keyword');
        this.clearTags('.tags');
      },

      clearTags: function (target) {
        $(target)
          .find('.result')
          .each(function (i, o) {
            $(o).remove();
          });
      },

      clearInputs: function (target) {
        $(target)
          .find('input, select')
          .each(
            function (i, o) {
              this.clearInput($(o));
            }.bind(this),
          );
      },

      clearInput: function ($element) {
        if ($element.is('select')) {
          $element.prop('selectedIndex', 0);
        } else if ($element.is('input')) {
          var type = $element.attr('type').toLowerCase();
          if (type == 'radio') {
            $element.prop('checked', false);
          } else {
            $element.val('');
          }
        }
      },
    };
    Object.create(FabricSearchResetButton).init($('.fabric-search-reset'));
  }
});

document.addEventListener('DOMContentLoaded', () => {
  function initializeFabricSlider(sliderId, prevButtonClass, nextButtonClass, itemClass) {
    // スライダー関連要素の取得
    const slider = document.getElementById(sliderId);
    const items = document.querySelectorAll(`.${itemClass}`);
    const prevButton = document.querySelector(`.${prevButtonClass}`);
    const nextButton = document.querySelector(`.${nextButtonClass}`);

    // 要素が存在しない場合のエラー処理
    if (!slider || items.length === 0 || !prevButton || !nextButton) {
      // console.error("Slider elements not found! Check your selectors and HTML structure.");
      return;
    }

    let currentIndex = 0;
    const visibleItems = 4; // 表示するアイテム数
    const totalItems = items.length;

    // スライダーを更新する関数
    const updateSlider = () => {
      slider.style.transform = `translateX(-${(100 / visibleItems) * currentIndex}%)`;
    };

    // 前ボタンのクリックイベント
    prevButton.addEventListener('click', () => {
      if (currentIndex > 0) {
        currentIndex--;
      } else {
        currentIndex = totalItems - visibleItems; // 最後にループ
      }
      updateSlider();
    });

    // 次ボタンのクリックイベント
    nextButton.addEventListener('click', () => {
      if (currentIndex < totalItems - visibleItems) {
        currentIndex++;
      } else {
        currentIndex = 0; // 最初にループ
      }
      updateSlider();
    });
  }

  // 関数の呼び出し
  initializeFabricSlider('fabricSlider', 'newarrival-btn.prev', 'newarrival-btn.next', 'newarrival-item');
});

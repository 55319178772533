import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="follow"
export default class extends Controller {
  static targets = ['button'];

  connect() {
    console.log('Follow controller connected!');
  }

  toggle(event) {
    event.preventDefault();
    const button = event.currentTarget;

    // ボタンを無効化してロード中を表示
    button.disabled = true;
    button.textContent = 'Loading...';

    const url = button.dataset.url; // data-url 属性から URL を取得
    const method = button.dataset.method;

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .then((data) => {
        if (data.status === 'followed') {
          this.updateButton(button, '♥ FOLLOWED', 'delete', data.unfollowPath);
          alert('フォローしました');
        } else if (data.status === 'unfollowed') {
          this.updateButton(button, '♡ FOLLOW', 'post', data.followPath);
          alert('フォローを解除しました');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        button.disabled = false;
      });
  }

  updateButton(button, text, method, url) {
    button.textContent = text; // ボタンのテキストを更新
    button.dataset.method = method; // ボタンの data-method 属性を更新
    button.dataset.url = url; // ボタンの data-url 属性を更新
  }
}
